<template>
  <div class="invoice-record">
    <div class="table-container">
      <el-form :inline="true" :model="filter" ref="filter" class="demo-form-inline">
        <el-form-item label="开票主体:">
          <div class="form-item">
            <el-select
              clearable
              size="small"
              style="width: 100%"
              v-model="filter.distributorContractInfoIds"
              multiple
              placeholder="请选择（可多选）"
            >
              <el-option
                :key="idx"
                :label="item.label"
                :loading="orderOptionsLoading"
                :value="item.value"
                v-for="(item, idx) in contractList">
              </el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item label="申请状态:">
          <div class="form-item">
            <el-select
              clearable
              size="small"
              style="width: 100%"
              v-model="filter.status"
            >
              <el-option
                :key="idx"
                :label="item.label"
                :value="item.value"
                v-for="(item, idx) in statusOptions"
              ></el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item label="品牌类型:">
          <div class="form-item">
            <el-select clearable size="small" style="width: 100%" v-model="filter.orderBrandType">
              <el-option
                :key="idx"
                :label="item.label"
                :value="item.value"
                v-for="(item, idx) in orderTypeOptions"
              ></el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button @click="onSubmit" type="primary" class="invoice__search">查询</el-button>
          <el-button @click="onReset" size="small">重置</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="recordData" ref="multipleTable" style="width: 100%" tooltip-effect="dark">
        <el-table-column label="开票ID" prop="id" min-width="110px"></el-table-column>
        <el-table-column label="发起时间" min-width="110px">
          <template slot-scope="scope">{{
            scope.row.createDate | parseTime('{y}-{m}-{d} {h}:{i}:{s}')
          }}</template>
        </el-table-column>
        <el-table-column label="包含订单" prop="orderNos" width="220px">
          <template slot-scope="scope">
            <div>
              {{ scope.row.orderNos[0] }}
              <el-button @click="moreOrderShow(scope.row.id)" size="small" type="text">更多</el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="开票主体" prop="id" min-width="150px">
          <template slot-scope="scope">
            <span v-if="scope.row.contractInfo">{{ scope.row.contractInfo.merchantTypeName + '-' + scope.row.contractInfo.contractInfoName }}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column
          label="申请金额"
          prop="totalAmount"
          width="auto"
        
        ></el-table-column>
        <el-table-column label="申请状态" prop="statusName" width="auto">
          <template slot-scope="scope"
            ><div>{{ scope.row.statusName }}</div>
            <div v-if="scope.row.deliveryNo">{{ scope.row.deliveryCompanyName }}：{{ scope.row.deliveryNo }}</div>
          </template>
        </el-table-column>
        <el-table-column label="品牌类型" prop="orderBrandTypeName" width="auto">
          <template slot-scope="scope"
            ><div>{{ scope.row.orderBrandTypeName }}</div>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <AddInvoice :id="scope.row.id" :ref="scope.row.id" :type="'order-details'">
              <template>
                <el-button @click="$refs[scope.row.id].show()" type="text" class="invoice__btn"
                  >查看详情</el-button
                >
              </template>
            </AddInvoice>
            <div>
              <el-tooltip
                v-if="scope.row.status === 'REJECT'"
                class="item"
                effect="dark"
                :content="scope.row.rejectReson"
                placement="left-start"
              >
                <el-button type="text" size="small" class="invoice__btn">查看驳回原因</el-button>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          :current-page="sendData.pageNo"
          :disabled="listLoading"
          :page-size="sendData.pageSize"
          :page-sizes="[10, 20, 30, 40]"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          background
          layout="total, sizes, prev, pager, next, jumper"
        ></el-pagination>
      </div>
      <!-- 开票订单 -->
    <el-dialog title="查看开票订单" :visible.sync="dialogOrder">
      <div class="dialogOrder-title">共{{ dialog.total }}笔订单申请了开票</div>
      <div class="dialog-table">
        <el-table :data="orderList" v-loading="listLoadingDialog">
          <el-table-column property="orderNo" label="开票订单号" width="auto" align="center"></el-table-column>
          <el-table-column property="amount" label="开票订单金额（元）" width="auto" align="center"></el-table-column>
        </el-table>
      </div>
      <div class="pagination">
        <el-pagination
          :current-page="dialog.pageNo"
          :disabled="listLoadingDialog"
          :page-size="dialog.pageSize"
          :page-sizes="[5, 10, 20, 30]"
          :total="dialog.total"
          @current-change="handleCurrentChangedialog"
          @size-change="handleSizeChangedialog"
          background
          layout="total, sizes, prev, pager, next, jumper"
        ></el-pagination>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogOrder = false">取 消</el-button>
        <el-button type="primary" @click="dialogOrder = false">确 定</el-button>
      </div>
    </el-dialog>
    </div>
  </div>
</template>
<script>
import { recordList, getOrderDetailByPageForMerchant, dictListByType, getListBriefForMerchant } from '@/api/invoice';
import AddInvoice from '@/components/AddInvoice';
import cloneDeep from 'lodash/cloneDeep';
export default {
  name: 'invoice-record',
  data() {
    const initFilter = {
      distributorContractInfoIds: [],
      status: '',
      orderBrandType: ''
    };
    return {
      dialogOrder: false,
            // 点击更多出现的弹窗
            listLoadingDialog: false,
      dialog: {
        pageNo: 1,
        pageSize: 10,
        total: 0
      },
      orderList: [],
      moreOrderId: '',
      recordData: [],
      sendData: {
        pageNo: 1,
        pageSize: 10,
      },
      total: 0,
      listLoading: false,
      statusOptions: [],
      orderTypeOptions: [{
        label: '自有仓',
        value: 'NORMAL'
      },
      {
        label: '合作仓',
        value: 'INTERNATION'
      }],
      contractList: [],
      initFilter,
      filter: cloneDeep(initFilter),
      orderOptionsLoading: false
    };
  },
  components: {
    AddInvoice,
  },
  created() {
    this.getList();
    this.getOrderList();
    this.dictListByType();
  },
  methods: {

    moreOrderShow(id) {
      this.dialogOrder = true;
      this.moreOrderId = id;
      this.listLoadingDialog = false;
      this.dialog = {
        pageNo: 1,
        pageSize: 10,
        total: 0
      };
      this.fetchMoreOrder();
    },

    handleSizeChangedialog(val) {
      this.dialog.pageSize = val;
      this.fetchMoreOrder();
    },
    handleCurrentChangedialog(val) {
      this.dialog.pageNo = val;
      this.fetchMoreOrder();
    },
    async fetchMoreOrder() {
      this.listLoadingDialog = true;
      const { pageNo, pageSize } = this.dialog;
      const listQuery = { pageNo, pageSize, data: { id: this.moreOrderId } };
      getOrderDetailByPageForMerchant(listQuery)
        .then((response) => {
          this.orderList = response.data.list || [];
          this.dialog.total = response.data.total;
        })
        .finally(() => {
          this.listLoadingDialog = false;
        });
    },
    async getList() {
      // 提取sendData中的pageNo和pageSize
      const { pageNo, pageSize } = this.sendData;
      // 生成新对象, 只拷贝filter属性
      const listQuery = { pageNo, pageSize, data: this.filter };
      const { data: res } = await recordList(listQuery);
      this.recordData = res.list;
      this.total = res.total;
    },
    handleSizeChange(val) {
      this.sendData.pageSize = val;
      this.sendData.pageNo = 1;
      this.getList();
    },
    // 主体列表
    getOrderList() {
      this.orderOptionsLoading = true;
      getListBriefForMerchant({})
      .then(rs => {
          if (rs && rs.data) {
            const res = rs.data.map(item => ({
              value: item.id,
              label: item.merchantTypeName + '-' + item.contractInfoName,
            }));
            this.contractList = res;
          }
        })
        .finally(() => {
          this.orderOptionsLoading = false;
        });
    },
    dictListByType() {
      dictListByType('invoice_apply_record_status').then(res => {
        if (res.code === '0') {
          this.statusOptions = res.data;
        }
      });
    },
    handleCurrentChange(val) {
      this.sendData.pageNo = val;
      this.getList();
    },
    // 筛选
    onSubmit() {
      this.sendData.pageNo = 1;
      this.sendData.pageSize = 10;
      this.getList();
    },
    // 重置
    onReset() {
      this.sendData.pageNo = 1;
      this.sendData.pageSize = 10;
      this.filter = cloneDeep(this.initFilter);
      this.$nextTick(() => {
        this.getList();
      });
    },
  },
};
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
.invoice-record {
  /deep/.el-table {
    font-size: 12px;
    color: #333;
    th {
      height: 40px;
      padding: 0;
      background-color: #f3f3f3;
      .cell {
        line-height: 12px;
      }
    }
  }
}
.invoice__btn {
  font-size: 12px;
  color: #2d89da;
}
.moreOrder {
  color: #2d89da;
  margin-left: 10px;
}
.form-item {
  width: 260px;
}
</style>